.file-upload-container {
    position: relative;   
    padding: 0px 20px 0px 35px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
}
.file-upload-container-scanning {
  position: relative;   
  padding: 0px 35px 0px 35px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
}
.drag-and-drop-text {   
    margin-top: 15% !important;
    margin-left: 20%;
    text-align: center;
    font-size: 15px;
}
.btn-add-files {    
    margin-left: 10%;
    margin-bottom: 25%;
    margin-right: 10%;
    margin-top: 25%;
    font-size: 12px;
    height: 30px;
    width: 100px;
    border-radius: 3px;
    border: none;
    background-color: #38598A;
    color: #FFFFFF;
}
.file-upload-input {
    font-size: 18px;
    display: block;   
    width: 0%;
    height: 0%;
    border: none;
    text-transform: none;
    position: absolute;
    opacity: 0;

    &:focus {
        outline: none;
    }
}

.file-upload-btn {
    font-size: 18px;
    display: flex;    
    width: 40%;
    height: 75%;
    text-transform: none;
    position: absolute;
}

.file-upload-preview {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    border: 4px solid gray;
    border-radius: 15px;
    width: 90%;
    height: 75%;
}
.file-upload-column {
    display: flex;
    flex-direction: column;
    flex-basis: 70% !important;    
  }
  .file-upload-column-2 {
    flex-basis: 1% !important;   
  }
  .file-upload-column-3 {
    flex-basis: 29% !important;
  }

  .file-upload-input-multiple {
    font-size: 18px;
      display: block;
      width: 0%;
      height: 0%;
      border: none;
      text-transform: none;
      position: absolute;
      opacity: 0;
  
      &:focus {
          outline: none;
      }
  }

.file-preview-container {
    margin-top: 40px;
    margin-bottom: 35px;
    margin-left: 5%;
    width: 80%;
    height: 100%;
    max-height: 250px;
    overflow-y: scroll;

  span {
    font-size: 14px;
    text-decoration: underline;
    font-weight: bold;
  }
}

.file-name {
    padding-right: 20px;
}
.foi-file-close {
  font: normal normal normal 14px/1 FontAwesome, sans-serif !important;
}
.error-message-ul {
  margin-left: 6%;
  color: red;
}
.error-message-container {
    margin-top: 10px;
    display: block;
    p {
      color:red;
      line-height: 1rem;
      font-size: 12px;
      width: 90%;
    }
    
}

.ol-display-none {
  list-style-type: none;
}

.pluscircle {
  margin: 0px 8px 8px 0px;
  position: relative;
  display: inline-flex;
  align-items: center;
  height: 24px;
}

.taglist-cfdpersonal {
  margin: 10px 9% 15px 9%;
}

.taglist {
  margin: 15px 9% 25px 9%;
}
.tagtitle {
  margin-left: 9%;
  margin-top: 5px;
}
.tag-message-container {
  margin: 5px 0px 0px 9%;
  display: block;
  p {
    line-height: 1rem;
    font-size: 12px;
  }
}
.tag-message-container-scanning {
  margin: 5px 0px 20px 9%;
  display: block;
  p {
    line-height: 1rem;
    font-size: 12px;
  }
  padding-right: 10px;
}

.scrollable-page {
    position: relative !important;  
    height: 100% !important;;  
}

#app {
    background-color: #f1f1f1 !important;
}

.foi-request-number-header {
    margin-top: 20px;
    padding: 0 !important;

}
.foi-request-form {
    background-color: white;
    margin-left: auto;
    margin-right: auto;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 10px;
}
.foi-review-request-container {
    
    width: 100%!important;
    height: auto!important;
    display: inline-block;
}

.foi-review-container {
    padding-top: 1px;    
    width: 75%!important;
    position: relative;
    margin-left: auto!important;
    margin-right: auto!important;
    right: 0px;
    left: 0px;
    background-color: white;
    
}

.foi-breadcrumb {
    margin-top: 15px !important;
    background-color: white !important;
}
.foi-breadcrumb-comments {
    padding-left: 2% !important;
}

.foi-details-card {
    margin-top: 50px;
    box-shadow: none !important;
    border: 1px solid #dfdede !important;
    border-radius: 2px;
}
.foi-details-label {
   // font-family: "BCSans-Regular", sans-serif;
    color: #000 !important;
    padding-left: 15px;

}
.foi-details-row {
    display: flex;
    width: 100%;
}
.foi-details-col {
    display: block;    
    align-items: flex-start; 
}

.btn-cancel {
    border: 1px solid #38598A;
    background-color: #FFFFFF;
    color: #38598A;
    border-radius: 3px;
    font-size: small;
    height: 30px;
    flex-basis: 50%;
    font-family: BCSans-Bold, sans-serif !important;
    margin-top: 10px;
}

.MuiOutlinedInput-notchedOutline {
    border: 1px solid !important;     
}


.MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
    border-color: #f44336 !important;
}
.MuiInputBase-root {
   // font-family: "BCSans-Regular", sans-serif !important;
}

.MuiFormLabel-root {
    font-family: "BCSans-Bold", sans-serif !important;
    color: black !important;   
}

.MuiFormLabel-root.Mui-error {
    font-family: "BCSans-Bold", sans-serif !important;
    color: #f44336 !important;

}

.MuiMenuItem-root {
    font-family: "BCSans-Regular", sans-serif !important;
}
.PrivateNotchedOutline-legendLabelled-4 {
    font-size: 0.80rem !important;
}

.foitabbedContainer{
height: fit-content;
}

.btn-axis-sync {
    background-color: #FFFFFF;
    border: 2px solid #38598A;
    border-radius: 4px;
    color: #38598A;
    margin-top: 10px;
    height: 51px;
    width: 179px;
    font-size: 16px;
    font-weight: bold;
}

.dialog-close-button {
    display: none;
}

.remaining-days-alert {
    font-size: 15px;
    font-weight: bolder;
    // padding-bottom: 1rem;
}

.request-accordian {

    .MuiAccordion-root {       
        border: #003366 1px solid;
        margin-top: 30px !important;
        margin-bottom: 30px !important;
        background-color: #FFFFFF !important; 
    }

    .MuiAccordionDetails-root {
        padding: 16px !important;
        display: block !important;
    }

    .MuiAccordionSummary-root {
        background-color: #003366 !important;  
    }

    .MuiIconButton-edgeEnd{
        margin-right: 0px !important;
    }

    > div > div > div > .MuiIconButton-label {
        color: white !important;
    }

    .MuiAccordionSummary-content.Mui-expanded {
        margin: 0px !important;
    }
}

.request-scrollbar-height {
    &::-webkit-scrollbar-track {
        margin-top: 105px !important;
    }
}

.foi-details-row-break {
    margin-bottom: 2em;
}

.applicant-profile-modal {
    z-index: 2000 !important;
}

.search-applicants-header {
    margin-left: 22px !important;
}

.applicant-profile-header{
    font-size: 25px !important;
    color: black;
}

.flexible-modal-drag-area {
    background: none !important;
}

.flexible-modal-resizer {
    position:absolute;
    right:0;
    bottom:0;
    cursor:se-resize;
    margin:5px;
    border-bottom: none !important;
    border-right: none !important;
    background-size: 16px;
    background-image: url("data:image/svg+xml,%3Csvg width='30' height='30' xmlns='http://www.w3.org/2000/svg' stroke='null' style='vector-effect: non-scaling-stroke;'%3E%3Cg id='Layer_1'%3E%3Ctitle%3ELayer 1%3C/title%3E%3Cellipse stroke='%23666666' ry='0.44882' rx='0.44882' id='svg_2' cy='28.93701' cx='18.62205' fill='none'/%3E%3Cellipse stroke='%23666666' ry='0.44882' rx='0.44882' id='svg_3' cy='19.01575' cx='28.38583' fill='none'/%3E%3Cline transform='rotate(45 23.4602 24.0105)' id='svg_4' y2='31.01042' x2='23.51658' y1='17.01051' x1='23.40384' stroke-width='2' stroke='%23666666' fill='none'/%3E%3Cline transform='rotate(45 19.7988 19.5616)' stroke='%23666666' id='svg_6' y2='32.63046' x2='19.85521' y1='6.49282' x1='19.74247' stroke-width='2' fill='none'/%3E%3Cellipse stroke='%23666666' ry='0.44882' rx='0.44882' id='svg_7' cy='10.27559' cx='28.93701' fill='none'/%3E%3Cellipse stroke='%23666666' ry='0.44882' rx='0.44882' id='svg_8' cy='28.77953' cx='10.66929' fill='none'/%3E%3Cline transform='rotate(45 15.4681 15.4671)' stroke='%23666666' id='svg_9' y2='34.10369' x2='15.52444' y1='-3.16948' x1='15.41171' stroke-width='2' fill='none'/%3E%3Cellipse stroke='%23666666' ry='0.44882' rx='0.44882' id='svg_10' cy='2.24409' cx='28.62205' fill='none'/%3E%3Cellipse stroke='%23666666' ry='0.44882' rx='0.44882' id='svg_11' cy='28.62205' cx='2.40158' fill='none'/%3E%3C/g%3E%3C/svg%3E");
}

#menu- {
    z-index: 2300 !important;
}

.btn-update-profile {
    border: none;
    background: none;
}

.foi-applicant-data-grid .MuiDataGrid-cellContent{
    text-transform: none !important;
}

.applicant-profile-modal-div {
    .flexible-modal-mask {
        position: static
    }
}
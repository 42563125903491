

#app > div {
    height: 100%!important;
}
div .foi-dashboard {    
   
    width: 100%;   
    background-position: center;
    background-size: cover;   
    position: relative;
}

.foi-container {
    
    width: 100%!important;
    height: 100vh;
    background-color: #f1f1f1;
}

.foi-grid-container {
    padding: 7% 1% 10% 1% !important;
    width: 80%!important;
    position: relative;
    margin-left: auto!important;
    margin-right: auto!important;
    background-color: white;   
    overflow: scroll;
    height: 100%;
}

.foi-dashboard-row2 {
    margin-top: 40px;
}

.MuiDataGrid-cell {
    text-transform: capitalize !important;
}

.foi-advanced-search-result-cell {
    padding: 0 !important;
    a {
        display: inline-block;
        width: 100%;
        height: 100%;
        line-height: 2.3;
        text-decoration: none;
        color: rgba(0, 0, 0, 0.87);
        div {
            padding: 0 10px;
        }
    }
    a:hover {        
        color: rgba(0, 0, 0, 0.87);
        text-decoration: none;
    }
}

.foi-request-queue-text {
    font-family: "BCSans-Bold", sans-serif!important;
    margin-bottom: 0;
}
.has-search .form-control {
    padding-left: 2.375rem;
    display: inline-block;
    color: #5a7296;   
    border: 1px solid #38598A;
}

.has-search .form-control-search {
    position: absolute;
    z-index: 2;    
    width: 2.375rem;
    height: 2.375rem;
    line-height: 2.375rem;
    text-align: center;
    pointer-events: none;
    color: #38598A;
    
}

.has-search .apply-btn {
    border: none;   
    background-color: #38598A !important;   
    font-family: 'BCSans-Bold', sans-serif!important;
    color: #FFFF; 
    margin-top: 10px;
    position: absolute;
    z-index: 2;    
    width: 30%;
}
.foi-request-type {
    margin-left: auto;
    display: inline-block;
}
.foi-general-radio 
{
    margin-left: 10px;
}
.foi-personal-radio 
{
    margin-left: 10px;
}
.foi-all-radio {
    margin-left: 10px;
}
input[type='radio'] {
    -webkit-appearance:none;
    width:15px;
    height:15px;
    border:1px solid darkgray;
    border-radius:50%;
    outline:none;    
  }
  
  input[type='radio']:hover {
    box-shadow:0 0 5px 0px #5a7296 inset;
  }
  
  input[type='radio']:before {
    content:'';
    display:block;
    width:60%;
    height:60%;
    margin: 20% auto;    
    border-radius:50%;    
  }
  input[type='radio']:checked:before {
    background:#38598A;
  }
.foi-data-grid {
    border: none !important;
}
.MuiDataGrid-iconSeparator {
    visibility: hidden;
}
.MuiDataGrid-columnsContainer, .MuiDataGrid-columnHeaders {
    font-family: 'BCSans-Bold', sans-serif !important;
    border-top: 2px solid;
    border-bottom: 2px solid !important;
}
.MuiDataGrid-row {
    border-bottom: 2px solid #e0e0dc;
}
.foi-dashboard-asignedTo .MuiOutlinedInput-input {
    padding: 5px 14px !important;
    width: 300%;   
}
.MuiBadge-badge {
    visibility: hidden;
}

.MuiDataGrid-columnHeaderTitleContainer {
    padding: 0px !important;
}
.MuiDataGrid-columnHeaderWrapper {
    font-weight: bolder !important;
}

.MuiDataGrid-columnHeaderTitle {
    overflow: visible !important;
    text-overflow: unset !important;
    font-family: 'BCSans-Bold', sans-serif!important;  
}

.MuiDataGrid-footerContainer {
    margin-bottom: 3%;
}

div.MuiDataGrid-row >div:last-child {
    width:1px!important;
}

div.MuiDataGrid-columnHeaderWrapper >  div:last-child {
    width:1px!important;
}

.MuiTablePagination-displayedRows {
    margin-bottom: 0px;
}

.scrollable-page {
    position: relative!important;
    height: -webkit-fill-available;
}
.foi-dashboard-header {
    display: flex;
}
.foi-btn-create {
    width: 50% !important;
    font-family: "BCSans-Bold", sans-serif!important;
    border: none !important;
    background-color: #38598A !important;
    color: #FFFFFF !important;
}

.form-control-label {
    margin:0 !important
}

.mui-radio {
    color: #38598A !important
}

nav.MuiPagination-root {
    background-color: white !important;
}

.floatAboveEverything {
	z-index: 999;
	position: absolute;
    margin: 125px auto auto 97%; 
}

.hideContent {
    display: none;
}

.floatAboveEverythingLeft {
	position: absolute;
    margin-left: 10px;
    margin-top: 170px;
}

.footerContent {
    padding: 10px 0;
    font-size: 0.875rem !important;
}

.footerContent ul {
    position: absolute;
    left: 50%;
    transform: translatex(-50%);
}

.footerContent .MuiNativeSelect-root::before {
    transition: none !important;
    border-bottom: 0px !important;
}

.footerContent .MuiNativeSelect-root::after {
    border-bottom: 0px !important;
}

.MuiNativeSelect-standard {
    padding-right: 15px !important;
}

.dashboard-flag-restricted {
    font-size: 10px;
    color: #A0192F;
    padding: 2px;
}

.dashboard-flag-oipcreview {
    font-size: 10px;
    color: #fa7c16;
    padding: 2px;
}

.dashboard-flag-phasedrelease {
    font-size: 10px;
    color: #9207b7;
    padding: 2px;
}

.dashboard-flag-placeholder {
    font-size: 10px;
    color: #c1bfbf;
    padding: 2px;
}

.table-cell-truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

div.MuiDataGrid-columnHeader[data-field="isiaorestricted"] {
    padding-right: 0px;
    button {
        left: 5px;
    }
}

.keywordLabel{
    font-size: 14px;
    margin: 0px 0px 0px 10px;
}
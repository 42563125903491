
  
div .home-page {
    background-image: url('../../../assets/FOI/images/BC.bmp');
    height:60vh;
    min-height: 90vh;
    padding-top: 60px;    
    background-position: center;
    background-size: cover;
}

div .rounded-rectangle {
    font-family: 'BCSans-Bold', sans-serif!important;
    border-radius: 12px;
    background: #FFFF;
    margin: auto;
    width: 25%;
    height: fit-content;
    margin-top: 200px;
}
div .login-container {
    padding: 6% 3% 5% 8%;   
  
}
div h1 {
    font-family: 'BCSans-Bold', sans-serif!important;    
    color: #494949;
    position: relative;
}

.foi-btn {
    border: none;   
    background-color: #38598A !important;   
    font-family: 'BCSans-Bold', sans-serif!important;
    color: #FFFF !important; 
    margin-top: 10px;
    position: relative;
    width: 30%;
}
.foi-dashboardbtn {
    border: none;   
    background-color: #38598A !important;   
    font-family: 'BCSans-Bold', sans-serif!important;
    color: #FFFF !important; 
    margin-top: 10px;
    position: relative;
  
}
.foiroundedrectangle h1{
    font-size: 30px;
}

/*  Mobile screens (phones, 500px and down) */
@media only screen and (max-width: 500px) {

.foiroundedrectangle{
    width: auto!important;
    margin-left: 5%!important;
    margin-right: 5%!important;
}


.foibtn{
    width: 50%!important;
}

}


/*  Mobile screens (phones, 500px and down) */
@media only screen and (min-width: 501px)  and (max-width: 1110px) { 

    .foiroundedrectangle{ 
        width: 50%!important;
    }

    

}
  

  
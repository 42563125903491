


.footerstyle {
    background-color: #003366;
    color: #ffff;   
   position: relative!important;
}

.footerstyle a   {
 // font-family: 'BCSans-Regular', sans-serif;
  color: #ffff;
  text-decoration: none;
  font-size: small;
  
}
.footerstyle a:hover
{
  color: #ffff;
  text-decoration: none;
}

.footerstyle ul {
  list-style: none;
  padding-inline-start: unset;
}

.footerstyle li {
  padding-top:3px;
  padding-bottom: 3px;
}

.foi-footer-nav{   
    width: 100%;
}


/*  Mobile screens (phones, 500px and down) */
@media only screen and (max-width: 500px) {

  .footerstyle {
      //padding: 5%!important;
      text-align: center;
      
     

  }
  .footerstyle a {
    font-size: 15px;
  }

}

@media only screen and (min-width:500px) and (max-width:1200px) {

  .footerstyle li {
    padding-top:0px;
    padding-bottom: 0px;
  }
  
  .footerstyle ul {
    margin-bottom: 5px;
  }
  .footerstyle a {
    font-size: 12px;
  }

}

.scrollable-page {
    position: relative !important;  
    height: 100% !important; 
}

#app {
    background-color: #f1f1f1 !important;
}

.foi-request-number-header {
    margin-top: 20px;
    padding: 0 !important;
}
.foi-request-form {
    background-color: white;
    margin-left: auto;
    margin-right: auto;
    padding-left: 5%;
    padding-right: 5%;
    padding-bottom: 10px;
}
.foi-review-request-container {
    
    width: 100%!important;
    height: auto!important;
    display: inline-block;
}

.foi-review-container {
    padding-top: 1px;    
    width: 75%!important;
    position: relative;
    margin-left: auto!important;
    margin-right: auto!important;
    right: 0px;
    left: 0px;
    background-color: white;
    
}

.foi-details-card {
    margin-top: 30px;
    box-shadow: none !important;
    border: 1px solid #dfdede !important;
    border-radius: 2px;
    padding-top: 10px;
}
.foi-details-label {
  // font-family: "BCSans-Regular", sans-serif;
    color: grey;
    padding-left: 15px;

}
.foi-details-row {
    display: flex;
    width: 100%;
    // margin: 0 !important;
    // justify-content: space-around;
}
.foi-details-col {
    display: block;    
    align-items: flex-start; 
}

.MuiOutlinedInput-notchedOutline {
    border: 1px solid !important;     
}


.MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
    border-color: #f44336 !important;
}

.MuiFormLabel-root {
    font-family: "BCSans-Bold", sans-serif !important;
    color: black !important;   
}

.MuiFormLabel-root.Mui-error {
    font-family: "BCSans-Bold", sans-serif !important;
    color: #f44336 !important;

}

.MuiFormLabel-root.Mui-disabled {
    font-family: "BCSans-Bold", sans-serif !important;
    color: black !important;
}

.MuiInputBase-input.Mui-disabled {
    background-color: #EEEEEE;
    color: black;
}

// .Mui-disabled.MuiInputBase-root {
//     background-color: #EEEEEE;
// }

.request-flag .MuiInputBase-input.Mui-disabled {
    background-color: transparent !important;
}

.PrivateNotchedOutline-legendLabelled-4 {
    font-size: 0.80rem !important;
}

.foi-bottom-button-group {
    display: flex;
    margin-top:25px;
    margin-bottom: 100px;
}

.btn-bottom {
    margin-right: 20px;
    margin-top: 10px;
    position: relative;
    width: 30%;
    font-family: "BCSans-Bold", sans-serif!important;
}

.foi-inline-grid{
    display:inline-grid;

}

.foi-justifyleft{
    justify-content:left
}

.foi-rowtoppadding{
    padding-top: 10px;
}

.foi-justify-spacyaround {
    justify-content: space-around;
}

.foi-ministry-requestheadertext {
    font-size: 25px;
}

.foi-floatright{
    float: right;
}

.foi-link{
    text-decoration: none;
    color: #036;
}

.nodivstages {
    color:red;
}

.state-box {
    padding-left:12px;
    color:white;
}
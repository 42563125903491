

.btn-save {
    border-radius: 3px;
    font-size: small;
    height: 30px;
    
    flex-basis: 50% 
}

.btn-save:disabled {
    opacity: 0.65;
}

// .btn-cancel {
//     border: 1px solid #38598A;
//     background-color: #FFFFFF;
//     color: #38598A;
//     border-radius: 3px;
//     font-size: small;
//     height: 30px;
//     flex-basis: 50%;
//     font-family: BCSans-Bold, sans-serif !important;
//     margin-top: 10px;
// }

#state-change-dialog-title {
    font-family: "BCSans-Bold", sans-serif!important; 
    padding: 30px 26px 30px 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.state-change-header {
    margin-left: 30px;
    color: #036 ;
    font-size: xx-large;
    font-weight: bold;
    font-family: "BCSans-Bold", sans-serif !important;
    flex-direction: column;
    flex-basis: 70% 
}

.title-col2 {
    flex-basis: 25%
}

.title-col3 {
    flex-basis: 5% !important
}


#state-change-dialog-title .MuiIconButton-root {
    background-color: #036 ;
    color: white;
    border-radius: unset;
}
#state-change-content {
    padding: 15px 50px 50px 50px;
}

#state-change-dialog-description {
    // padding-left: 50px;
    display: block;
    color: black; 
}

.confirmation-message {
    font-size: 20px;
}

.table-assignedto {
    width: 80% !important;
    margin-left: 20px;
    margin-top: 1rem;
}

.table-assignedto th {
    width: 40%;
}

.MuiDialogActions-root {
    padding: 20px 20px 30px !important;
    justify-content: center !important;
    flex-direction: row;
}

.table th, .table td {
    padding: 0.4rem 0.4rem 0.4rem 0.75rem !important;
}

.confirm-modal-row {
    margin: 30px;
    padding: 0px 40px;
}

.first-row {
    margin-top: 0px;
}

.confirm-label-area {
    padding-left: 5px;
}

.confirm-label-content {
    padding-left: 35px;
}

.modal-message {
    margin-left: 50px;
    margin-bottom: 20px;
}


.dialog-content {
    margin: auto;
}

.dialog-content-nomargin {
    margin: initial;
}

#state-change-dialog .MuiDialogContent-root, #state-change-dialog .MuiDialogActions-root {
    padding: 20px 100px !important;
}

#state-change-dialog-checkbox {
    // justify-content: flex-start !important;
    padding-bottom: 20px;
    padding-left: 100px;
}

.state-change-dialog-error {
    font-size: 14px;
    padding: 20px 100px;
    color: #9e2929;
}

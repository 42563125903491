/* add css module styles here (optional) */
#Attachments{
  height: 100%;
  width: auto;
  background-color:white;
}

.actionsBtn, .childActionsBtn{
  background-color: transparent;
  border: none;
  border-radius: 50%;
  cursor: pointer;
}

.actionsBtn{
  padding: 6px 12px;
}

.childActionsBtn{
  padding: 10px 10px;
}

.attachment-popup-content {
  margin-top: -35px;
  z-index: 1031!important;
}

[role='tooltip'].attachment-popup-content {
  width: 150px!important;
  border: 1 px solid #d7d7d7!important;
  box-shadow: rgba(0, 0, 0, 0.4) 2px 2px 0px!important;
}

[data-popup='tooltip'].attachment-popup-overlay {
  pointer-events: auto!important;
}

.attachment-name {
  font-size: 16px;
  font-weight: 700;
  display: inline;
  padding-right: 15px;
  letter-spacing: 0;
  line-height: 22px;
}

.attachment-time, .attachment-owner {
  font-size: 14px;
  font-weight: 300;
  font-style: italic;
  letter-spacing: 0;
  line-height: 19px;
}

.extension-name {
  padding-left: 10px;
  padding-top: 9px;
  text-align: center;
  font-size: 23px;
}

.attachment-disabled {
  color:lightslategrey;
}

.foi-export-button {
  margin: 0;
  width: 100%;
  background-color: #FFFFFF;
  color: #38598A !important;
  outline-color: #38598A;
  outline-style: solid;
  outline-offset: -3px;
  font-family: BCSans-Bold, sans-serif !important;
}

.foi-export-button:focus {
  outline-color: #38598A !important;
  outline-style: solid !important;
  outline-offset: -3px;
}

.foi-export-button:focus:not(:focus-visible) {
  outline: #38598A solid !important;
  outline-offset: -3px;
}

button[disabled]{
  cursor:not-allowed!important;
}

.addCorrespondence {
  color: white !important;
  background-color: #38598A !important;
  width: 100%;
  max-width: 200px;
  margin: 0;
  font-family: BCSans-Bold, sans-serif !important;
  background: linear-gradient(to right, #38598A 80%, white 20%);
  .MuiFormLabel-root {
    color: #FFFFFF !important;
    text-align: center;
    width: 60%;
  }
  .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border-color: #38598A !important;
  }
}

.addCorrespondence-menuitem {
  li.MuiMenuItem-root {
    background-color: #FFF !important; 
    &:hover,
    &:focus {
      background-color: #e3f2fd !important;
    }
  }
}

.previewEmail {
  float: right;
  button {
    width: fit-content !important;
    height: fit-content !important;
    padding: 3px 25px !important;
    margin-left: 1em;
  }
}

.contact-applicant-container {
  margin-top: 60px;
  margin-left: 1em;
  margin-right: 1em;
}

.search-grid-container {
  display: inline-block !important;
  margin: 20px 0 !important;
}

.search-grid {
  border-right: 2px solid #38598A;
  background-color: rgba(56,89,138,0.1);
}

.search-grid-buttons {
  margin-right: auto;
}

.search-icon {
  color: #38598A;
}

.correspondence-editor {
  border: 1px solid #003366 !important;
  border-radius: 8px;
  .ql-container.ql-snow {
    height: 200px;
    border: none
  }
}

#correspondence-editor-ql-toolbar {
  padding-right: 0;
}

.email-template-dropdown{
  margin-top: 8px !important;
  > div {
    > fieldset {
      > legend {
        width: 0;
      }
    }
  }
}

.template-list-item {
  border: ridge;
  border-right: ridge;
  border-bottom: none;
  cursor: pointer;
}
.template-list-item-last {
  border-bottom: ridge;
}

.email-attachment-item {
  background-color: #4e7095;
  color: white;
  margin: 10px 15px;
  padding: 5px 10px;
  i {
    float: right;
    font-size: 20px;
    margin-top: 2px;
  }
}

.preview-container {
  margin-left: 1em;
  margin-right: 1em;
}

.preview-frame {
  width: 100%;
  min-height: 500px;
  max-height: 900px;
  border: none;
  overflow: hidden;
}

.tooltip-floatRight {
  cursor: pointer;
  display: block;
  z-index: 2;
  position: absolute;
  right: -20px;
}

.tooltip-floatRight button{
  padding: 0px 20px 0px 0px !important;
  min-width: 0px !important;
}

.tooltip-preview {
  top: 470px;
}

.closeDraft {
  height: 30px;
  text-align: right;
}

.templateList{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  .email-attachment-item{
    background-color: #4e7095;
    color: white;
    margin: 10px 15px;
    padding: 5px 10px;
    width: 100%;
    a {
      color: white;
      text-decoration: underline;
    }
  }
     
  .templateInfo{
    display: flex;
    margin: 4px 0;

    .templateUser{
      display: flex;
      margin-left: 10px;
      font-size: 13px;
      font-weight: bold;
      padding-right: 10px;
      margin-top: 4px;
    }                
  }
  
  .userActions{  
    position: relative;

    .actionsBtn{
      float: right;
      background-color: transparent;
      border: none;
      padding: 6px 12px;
      border-radius: 50%;
      cursor: pointer;

      &:hover{
        outline:none;
        background-color: #dbdbdb5d;
      }
      &:focus {outline:0;}
      
    }
    .replyBtn{
      position: absolute;
      bottom: 0;
      right: 0;
      vertical-align: bottom;
      background-color: transparent!important;
      border:none;
      color: #003366;
      outline:none;
      font-weight: 600;
      font-size:13px;       
      width:70px;
      padding: 5px;
      border-radius: 4px;
      
      &:hover{
        outline:none;
        background-color: rgba(160, 160, 160, 0.315);
      }
      &:focus {outline:0;}

      svg {
        color: #003366;
      }
    }
    
  }
}

.templateTime{
  padding-left: 10px;
  font-size: 13px;
  font-weight: 300;
  font-style: italic;
  letter-spacing: 0;
  line-height: 19px;
  margin-top: 4px;
}

.communication-accordion {
  box-shadow: none !important;
  border: none;
  .MuiAccordionSummary-root {
    background-color: rgb(210, 226, 246) !important;
    color: black;
    .MuiAccordionSummary-content {
      .userActions {
        justify-self: right;
        margin-left: auto;
      }
      .MuiButtonBase-root {
        justify-self: right;
        color: #003366;
      }
      .MuiIconButton-root {
        color: #003366;
        padding: 6px;
        fill: #003366;
      }
    }
  }
  .MuiAccordionDetails-root {
    background-color: rgb(210, 226, 246);
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .MuiPaper-elevation1 {
    box-shadow: none;
  }
  .email-attachment-item{
    background-color: #4e7095;
    color: white;
    margin: 10px 15px;
    padding: 5px 10px;
    width: 100%;
    a {
      color: white;
      text-decoration: underline;
    }
  }
}

.ql-link .ql-stroke,
.ql-link .ql-fill {
  display: none !important; 
}

.ql-link {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.ql-link::before {
  content: '';
  display: inline-block;
  width: 16px; 
  height: 16px;
  background-color: currentColor; 
  mask: url('../../../../assets/styles/attachment-icon.svg') no-repeat center;
  -webkit-mask: url('../../../../assets/styles/attachment-icon.svg') no-repeat center;
  background-size: contain;
}

.state-change-email-note { 
  margin-left: 25px;
  margin-bottom: 20px;
  font-style: italic;
}
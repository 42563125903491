
.foi-ministries-container {
  margin-top: 50px;
 
}


.foi-ministries-checkboxes {
  margin-top: 20px;
  display: block;
}
  .check-item{       
    position:relative;     
    margin: 0.5%;
    padding: 2px;
    width: 10%;
    padding-left: min(3.5%, 30px);

  }
  .checkmark{
    position:absolute;
    top:4px;
    left:0;
    
    height:20px;
    width:20px;
    background-color:#eee;
    border-radius: 3px;
    border: 1px solid;
  }
    
  .check-item:hover input[type=checkbox]:not([disabled]) ~ .checkmark{    
    cursor: pointer;
  }
    
  .check-item input:checked ~ .checkmark{
    background-color:green;
    
  }
    
  .checkmark:after{content:"";
    position:absolute;
    display:none;
    color: green;
  }
    
  .check-item input:checked ~ .checkmark:after{
    display:block;
    color: green;
  }
    
  .check-item .checkmark:after{
    left:7px;
    top:3px;
    width:5px;
    height:10px;
    border:solid white;    
    border-width:0 3px 3px 0;
    -webkit-transform:rotate(45deg);
    -ms-transform:rotate(45deg);
    transform:rotate(45deg);
  }
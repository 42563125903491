[role=tooltip].tooltipLeft-content {
    margin: -8px -8px!important;
}

.tooltip-arrow {
    display: none;
}

.tooltipTitle {
    font-size: 14px;
    font-weight: bold;
}

.tooltipContent {
    font-size: 12px;
}
    
.ministries-canvassed{

    display: flex;
    justify-content: center;

    table{
        min-width: 60%;
    }
    
    td {
        width: auto;
    }
    
    td.row-width {
        width: 0%;
        white-space: nowrap;
    }
}

.ministry-name{
    font-weight: bold;
}

.ministry-requestid{
    cursor: pointer;
    text-decoration: underline;
    color: #38598A;
}

.title-col3 {
    flex-basis: 5% !important
}

.state-change-header {
    margin-left: 30px;
    color: #036 ;
    font-size: xx-large;
    font-weight: bold;
    font-family: "BCSans-Bold", sans-serif !important;
    flex-direction: column;
    flex-basis: 70% ;
    margin-bottom: 0px;
}
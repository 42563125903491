#app > div {
  height: 100%!important;
}

.admin-container {
  width: 100%!important;
  height: 100vh;  
  padding: 5% 1% 10% 1% !important;
  background-color: #f1f1f1;
}

.admin-grid-container {
  width: 80%!important;
  position: relative;
  margin-left: auto!important;
  margin-right: auto!important;
  overflow: scroll;
}

.admin-title {
  margin-bottom: 0.5em;
}

.disable-cursor {
  pointer-events: none;
}

.refresh-cache {
  background-color: #fff;
  border: 2px solid #38598a;
  border-radius: 6px;
  color: #38598a;
  margin-top: 10px;
  height: 40px;
  font-size: 16px;
  font-weight: 500;
}


.close-btn-align{
    text-align: right;
    cursor: pointer;
    color: #003366;
}

.list-group-item:first-child {
    border-top: transparent;
}

.notification-comment{
    overflow: hidden;
    text-overflow: ellipsis;
    font-style: italic;
    font-size: 16px;
    white-space: nowrap;
}

.notification-item-footer{
    font-size:12px;
    font-style:italic;
    padding-top:5px;
}

.notification-heading {
    font-size: 15px;
    font-weight: bold;
    margin-bottom: 10px;
}

.redirect-url {
    cursor: pointer;
    color: #38598A;
    text-decoration: underline;     
}

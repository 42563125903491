.ministry-request-description-row {
    display: inline-flex;
    margin-bottom: 20px;
}

.ministry-heading {
    font-size: 16px !important;
}

.ministry-request-dates {
    display: inline-flex;
    margin-left: 100px;
}

.ministry-start-date {
    margin-right: 50px !important;
}

.ministry-bottom-request-description-header {
    margin-bottom: 10px !important;
    
}
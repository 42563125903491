


.MuiAccordion-root {       
    border: #c9c7c7 1px solid;
    margin-bottom: 30px !important;
}

.MuiAccordionSummary-root.Mui-expanded {
    min-height: 48px !important;
}

.MuiAccordionSummary-root {
    background-color: #eceaea !important;  
}

.acc-details {
    background-color: #ffffff !important;
    padding: 16px 16px 16px !important;
}
.acc-request-description {
    margin-right: auto !important;
    font-size: unset !important;
    font-weight: unset !important;
}

.acc-username-date {
    font-size: unset !important;
    font-weight: unset !important;
}

.acc-request-dates {
    display: inline-flex;
    margin-left: 100px;
}

.acc-start-date {
    margin-right: 50px !important;
}

.acc-details-1 {
    margin-left: 50px !important;
}

.acc-request-description-row {
    display: inline-flex;
    margin-bottom: 20px;
}

.acc-bottom-request-description-header {
    margin-bottom: 10px !important;
}

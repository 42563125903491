.active {
  display: block !important;
}

.foiformcontent {
  display: inline-flex;  
  padding-top: 65px;
  position: relative;
  overflow-y: scroll;
  margin-left: 10%;
  margin-right: 10%;
  height: 90%;
  min-width: 80%;
}

.foitabbedContainer{
  display: inline-flex;  
  background-color: white;  
  min-width: 100%;
}

  
  .leftpanelheader{
      display: inline-block;
  }

  .foitabheadercollection  span.MuiTouchRipple-root {
    height: 10px;
    width: 10px;
    background-color:white;
    border-color: teal;
    border-radius: 50%!important;    
    border-color: teal;
    border-width: 1px;
    border-style: solid;
    
    left: 5% !important;
    top: unset !important;
    right: unset!important;
    bottom: unset!important;

  }
 

  .foi-review-container {
    margin-left: 0px !important;
    margin-right: 0px !important;
    width: 100% !important;
  }

  .foitabheadercollection {
    width: 200px;
    // height: calc(100% - 96px - 67px);
    // height: 100%;
    border-radius: 0px 30px 30px 0px!important;
    margin-bottom: auto;
    padding-bottom: 8%;
    min-width: 200px;
    position: fixed;
    z-index: 10;
  }
  .foitabpanelcollection{
    margin-left: 200px;
    min-width: calc(80vw - 200px);
    max-width: calc(80vw - 200px);
  }

  .foitabheaderdefaultBG {
    background-color: #C45303;
  }

  .foitabheaderIntakeInProgressBG, .foitabheaderAppFeeOwingBG, .foitabheaderSection5Pending {
    background-color: #8C3601;
  }

  .foitabheaderOpenBG {
    background-color: #1D8800;
  }

  .foitabheaderClosedBG{
    background-color: #1A1A1A;
  }

  .foitabheaderCFRG{
    background-color: #D0017A;
  }

  .foitabheaderCFROverdueBG{
    background-color: #ff0000;
  }
  
  .foitabheaderRedirectBG{
    background-color: #0E4602;
  }
  
  .foitabheaderReviewBG{
    background-color: #04596C;
  }
  
  .foitabheaderRecordsReadyForReviewBG{
    background-color: #04596C;
  }
  
  .foitabheaderFeeBG{
    background-color: #721121;
  }

  .foitabheaderConsultBG{
    background-color: #7A3A9C;
  }

  .foitabheaderSignoffBG{
    background-color: #4B296B;
  }

  .foitabheaderDeduplicationBG{
    background-color: #B1063F;
  }

  .foitabheaderOnHoldBG{
    background-color: #595959;
  }

  .foitabheaderHarmsBG{
    background-color: #832AB7;
  }

  .foitabheaderResponseBG{
    background-color: #07437F;
  }

  .foitabheaderPeerreviewBG{
    background-color: #096DD1;
  }
  .foitabheaderTaggingBG{
    background-color: #9B1048;
  }
  .foitabheaderReadytoScanBG{
    background-color: #A2096C;
  }
  .foitabheaderOnholdOtherBG{
    background-color: #595959;
  }

  .foileftpanelheader
  {
      padding-left: 12%;
      padding-top: 20%;
      padding-bottom: 10%;
  }

  .foileftpanelheader h1 {
        color: white;
  }

.foileftpanelheader h1 > a {
    text-decoration: none!important;
    color: white;
}
.foileftpanelrequestno {
  color: white;
  font-weight: bold;
  // margin-left: 10px;
  padding-left: 6%;
  margin-top: 20px !important;
}

.foileftpaneldropdown {
  padding-left: 6%;
  padding-top: 5%;
  padding-bottom: 10%;
  padding-right: 6%;
}
.foileftpanelstatus h4{
  font-size: 15px;
  font-weight: bolder;
  padding-bottom: 1rem;
}

  .foileftpanelstatus
  {
    position: absolute;
    bottom: 0;
    padding-bottom: 20%;
    padding-left: 10%;
    color: white;
    font-size: 15px;
    font-weight: bolder;  
  }

.MuiBox-root {
    padding: 0px!important;
    
}
.foitabheadercollection .MuiButtonBase-root{
    padding-right: 0px!important;
}
.MuiTab-wrapper{
    padding-right: 10px!important;
    text-transform: none;
    text-align: left;
    font-size: 14px;
    font-weight: bold;
    align-items: unset!important;
    padding-left: 15px;
}
.MuiTabs-vertical {    
    width: auto;
    color: white;
    border-style: none!important;
}

.foitabbedContainer .Mui-selected {
    background-color: white!important;
    color: teal!important;
    margin-left: 10px!important;
    border:0px!important;
    border-style: none!important;
}

.MuiTabs-indicator {
    background-color: transparent!important;
}




/* Style the tab */
.tab {
  // overflow: hidden;
  // margin-bottom: 50px;
 
}

/* Style the buttons inside the tab */
.tab div.tablinks {
  background-color: inherit;
  color: white !important;
  border: none;
  outline: none;
  cursor: pointer;
  
  transition: 0.3s;
  // font-size: 17px;
  height: 48px;
  padding:8px;
  align-items: center;
  vertical-align: middle;
  text-align: left;
  margin-left:10px;

  padding-left: 15px;
}

/* Create an active/current tablink class */
.tab div.active {
  background-color: #fff;
  color:black !important;
}

span.circle
{
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background: white;  
  display: inline-block;
}

.tab div.active span.circle{
  background: #048ba8;
}

.foitabheaderCFRG .tab div.active span.circle {
  background-color: #ec7d10;
}

.foitabheaderCFROverdueBG .tab div.active span.circle {
  background-color: #ff0000;
}

.foitabheaderOpenBG .tab div.active span.circle{
  background-color: #008000;
}

.foitabheaderClosedBG .tab div.active span.circle{
  background-color: #4b296b;
}

.foitabheaderRedirectBG .tab div.active span.circle{
  background-color: #dd1155;
}

.foitabheaderReviewBG .tab div.active span.circle{
  background-color: #40E0D0;
}

.foitabheaderFeeBG .tab div.active span.circle{
  background-color: maroon;
}

.foitabheaderConsultBG .tab div.active span.circle{
  background-color: grey;
}

.foitabheaderSignoffBG .tab div.active span.circle{
  background-color: skyblue;
}

/* Style the tab content */
.tabcontent {
  display: none;
}

.foitabpanelcollection .active {
  display: inline-block;
}



@media screen and (min-width: 1000px) and (max-width: 1230px) {
  .foiformcontent {
    margin-left: calc(51.68vw - 512px);
    margin-right: calc(51.68vw - 512px);
    .foitabbedContainer {
      .foitabpanelcollection{
        min-width: calc(824px - 3.36vw);
        max-width: calc(824px - 3.36vw);
      }
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 1000px) {
  .foiformcontent {
    margin-left: 5px;
    margin-right: 5px;
    max-width: calc(100vw - 10px);
    .foitabbedContainer {
      .foitabpanelcollection{
        min-width: calc(100vw - 210px);
        max-width: calc(100vw - 210px);
      }
    }
  }
  .foileftpanelstatus, .remaining-days-alert, .foileftpanelrequestno {
    font-size: 15px;
    font-weight: bolder;
  }  

}

@media screen and (min-width: 651px) and (max-width: 767px) {

  .foileftpanelstatus, .remaining-days-alert, .foileftpanelrequestno {
    font-size: 12px !important;
    font-weight: bolder !important;
  }

  div.tab {
    margin-bottom: 50px;
  }

  div.tab div.tablinks {
    font-size: 12px !important;
    height: 28px !important;
  }

  div.foi-state-dropdown .MuiInputBase-root {
    font-size: 12px !important;
  }
}


@media screen and (min-width: 500px) and (max-width: 650px) {

  .foileftpanelstatus, .remaining-days-alert, .foileftpanelrequestno {
    font-size: 10px !important;
    font-weight: bolder !important;
  }

  div.tab div.tablinks {
    font-size: 10px !important;
    height: 25px !important;
  }
 
  div.foi-state-dropdown .MuiInputBase-root {
    font-size: 10px !important;
  }
}
$primary-blue: #036 !important;
$white: #fff !important;
$yellow: #fcba19;
$purple: #7979d4;
$green: #43893e;
$light-white:rgba(255,255,255,0.95);
$black:#000;
$light-blue: #4d61fc;
$light-grey: #f3f3f3;
$dark-blue: #1a5a96;


#foi-status-dropdown {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    margin-top: 10px;
    margin-bottom: 10px;
}

#foi-status-dropdown.Mui-disabled {
    cursor: not-allowed;
}

.foi-state-dropdown .MuiOutlinedInput-root {
    color: white !important;
} 

.foi-state-dropdown .MuiOutlinedInput-root .MuiSvgIcon-root {
    color: white !important;
} 
.foi-state-menuitem  {
    padding-left: 0px !important;
}
.foi-menuitem-span {
    width: 10px;
    height: 30px;
    display: inline-block;
    margin-right: 6px;
}

#foi-status-dropdown .foi-menuitem-span {
    display: none !important;
}

#foi-status-dropdown-label {
    display: none;
}

.unopened {
    background-color: #C45303;
}

.intakeinprogress, .section5pending {
    background-color: #8C3601;
}

.open {
    background-color: #1D8800;
}

.redirect {
    background-color: #0E4602;
}

.callforrecords {
    background-color: #D0017A;
}

.deduplication {
    background-color: #B1063F;
}

.feeestimate {
    background-color: #721121;
}

.onhold {
    background-color: #595959;
}

.appfeeowing {
    background-color: #8C3601;
}

.harmsassessment {
    background-color: #832AB7;
}

.consult {
    background-color: #7A3A9C;
}

.ministrysignoff {
    background-color: #4B296B;
}

.recordsreadyforreview {
    background-color: #04596C;
}

.recordsreview {
    background-color: #04596C;
}

.response {
    background-color: #07437F;
}

.closed {
    background-color: #1A1A1A;
}

.peerreview {
    background-color: #096DD1;
}

.tagging {
    background-color: #9B1048;
}

.readytoscan{
    background-color: #A2096C;
}
.onhold-other {
    background-color: #595959;
}
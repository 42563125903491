.align-division{
    text-align: center;
}

.divisions-row{
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 20px;
}

.arrow {
    display: inline-flex;

    .line {
        margin-top: 14px;
        // width: 320px;
        width: 258px;
        background: #979797;
        height: 1px;
        float: left;
        }
        
    .point {
        width: 0;
        height: 0;
        border-top: 5px solid transparent;
        border-bottom: 5px solid transparent;
        border-left: 10px solid #979797;
        float: right;
        margin-top: 9px;
    }
}

.MuiDivider-root {
    margin: 10px 0px 16px 0px;
    height: 1.5px;
}
  
[role=tooltip].tooltip-content {
    width: 150px;
    border-radius: 0px;
    position: absolute;
    margin: -10px auto 30px 30px;
}

.tooltip-content {
    border: 1px solid #38598A;
    background-color: #E5EAEF;
}

[role=tooltip].tooltipLeft-content {
    margin: -8px -8px!important;
}


.tooltip-arrow {
    // color: rgba(56,89,138,0.1);
    // stroke: #38598A;
    display: none;
}

.tooltipTitle {
    font-size: small;
    font-weight: bold;
}

.tooltipContent {
    font-size: smaller;
}
$white: #fff;
$grey: #6c757d;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #868e96;
$gray-700: #495057;
$gray-800: #373a3c;
$gray-900: #212529;
$black: #000;

$blue: #2780e3;
$indigo: #6610f2;
$purple: #613d7c;
$pink: #e83e8c;
$red: #ff0039;
$orange: #f2661f;
$yellow: #fcba19;
$green: #3fb618;
$teal: #20c997;
$cyan: #9954bb;
$lightblue: #4d61fc;

$primary: $blue;
$secondary: $grey;
$success: $green;
$info: $lightblue;
$warning: $yellow;
$danger: $red;
$light: $gray-100;
$dark: $gray-800;

@import "~bootstrap/scss/bootstrap.scss";
@import "~font-awesome/scss/font-awesome";


@font-face {
  font-family: "BCSans-Regular";
  src: url("./assets/FOI/fonts/BCSans-Regular.woff") format('woff');
}

@font-face {
    font-family: "BCSans-Bold";
    src: url("./assets/FOI/fonts/BCSans-Bold.woff") format('woff');
}

.container,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  max-width: 100vw;
}
.container {
  padding-left: 0;
  padding-right: 0;
  //height: 100vh;
  height: 100%;
}
.task-container{
  height: 100% !important;
}
.logo {
  height: 1.9em;
}
h4 {
  font-size: 1.2rem;
}
#main {
  /* margin-top: 2px;*/
  min-height: 85vh;
  //max-height: 85vh;
  padding-bottom: 1.5rem;
  overflow-y: auto;
  overflow-x: hidden;
}
body {
  font-size: 16px;
  font-family: 'BCSans-Regular', sans-serif !important;
}

div {
  font-family: 'BCSans-Regular', sans-serif !important;
}

.MuiInputBase-input {
  font-family: 'BCSans-Regular', sans-serif !important;
}

[class^="Mui"]
{
  font-family: 'BCSans-Regular', sans-serif;
}

[class^="Mui"] > *
{
  font-family: 'BCSans-Regular', sans-serif;
}

.loader {
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.input-group {
  .input-group-addon {
    position: absolute;
    right: 5px;
    top: 7px;
  }
  .radio-inline {
    margin-left: 20px;

    label {
      margin-right: 2rem;
    }
  }
}
.well {
  border: 1px solid #eeeeee;
  padding: 1rem;
  background-color: #f8f8f8bf;
}
.formio-component-panel {
  border: 1px solid#eeeeee;

  .panel-heading {
    background-color: #eeeeee;
    padding: 10px 0 10px 10px;

    .panel-title i {
      font-size: 12px;
    }
  }
  .panel-body {
    padding: 1.5rem 2rem 1.5rem 2rem;
  }
}
.page-item.active .page-link {
  background-color: #036 !important;
  border-color: #56595d !important;
}
h5,
.h5 {
  font-size: 16px !important;
}
.bg-default {
  background-color: #38598a;
  border: #38598a;
  color: white !important;
}
.text-muted {
  color: #868e96 !important;
}

i.fa.fa-question-circle.text-muted {
  color: #2e96ff !important;
}

.panel-heading {
  background-color: #38598a;
  border: #38598a;
  color: white !important;
  padding: 0.75rem 1.25rem;
  margin-bottom: 8px;
}
.formio-error-wrapper,
.has-error {
  color: red;
  background-color: #fff;
  border-color: red;

  label {
    color: red;
  }
}
.formio-errors .error {
  color: red;
}
.is-invalid {
  background-color: #ffd1d1;
}
.nav-dropdown {
  .dropdown-menu {
    width: 15rem;
    height: 14rem;
    padding: 15px 20px 0 20px;
    right: 0;
    top: 2rem;
    left: auto;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  }
  .dropdown-toggle {
    color: white !important;
    a {
      text-decoration: none;
    }
  }
  .userIcon {
    padding: 0 0 0 0;
    font-size: 2rem;
    float: right;
    color: #1264b6e0;
  }
}
.nav-dropdown :hover {
  text-decoration: none !important;
}
.nav-custom-tab {
  padding-left: 60px !important;
}
.main-nav {
  text-decoration: none !important;
  color: $black !important;
  font-size: 20px !important;
}
.nav-user-role {
  color: #003366;
  font-size: 15px;
  font-weight: bold;
  padding: 0 0;
}
.nav-user-email {
  font-size: 14px;
  padding: 0 0;
  color: #373a3c;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.nav-user-name {
  font-size: 20px;
  padding: 0 0;
  font-weight: 600;
  color: #373a3c;
}
.nav-logout {
  cursor: pointer;
  padding: 0 0;
  font-size: 16px;
}
.nav-tabs .nav-link.active {
  color: #4d4d4d;
  background-color: #f5f5f5;
  border-color: #f5f5f5 !important;
}
.nav-link {
  a {
    color: black;
  }
}
.active {
  background-color: $white;
}
.active-tab {
  //color: $lightblue !important;
  //border-bottom: 1px solid red;
  background-color: transparent;
  filter: invert(36%) sepia(38%) saturate(7152%) hue-rotate(227deg) brightness(101%) contrast(98%);
}

.active-tab-dropdown > #dashboard-dropdown {
  //color: $lightblue !important;
  //border-bottom: 1px solid red;
  background-color: transparent;
  filter: invert(36%) sepia(38%) saturate(7152%) hue-rotate(227deg) brightness(101%) contrast(98%);
}
.active-tab-dropdown > #task-dropdown {
  //color: $lightblue !important;
  //border-bottom: 1px solid red;
  background-color: transparent;
  filter: invert(36%) sepia(38%) saturate(7152%) hue-rotate(227deg) brightness(101%) contrast(98%);
}
.dropdown-item:hover{
  filter: none;
  background-color: #e9ecef;
}
.selected-tag {
  color: $lightblue !important;
  border-bottom: 1px solid $lightblue;
  background-color: transparent;
}
.lbl-logout {
  font-size: 16px;
  color: #373a3c;
  margin-left: 10px;
  cursor: pointer;
}
// .dropdown-menu {
//   height: 17em;
//   overflow-y: scroll;
// }
.dropdown-menu:before {
  position: absolute;
  top: -7px;
  right: 9px;
  display: inline-block;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #ccc;
  border-left: 7px solid transparent;
  border-bottom-color: rgba(0, 0, 0, 0.2);
  content: "";
}

.dropdown-menu:after {
  position: absolute;
  top: -6px;
  right: 10px;
  display: inline-block;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #ffffff;
  border-left: 6px solid transparent;
  content: "";
}
.taskDropdown {
  .dropdown-menu::before {
    left: 10%;
    right: 100%;
  }
  .dasboard-icon-dropdown{
    margin-bottom: 5px;
  }
  .task-dropdown-icon{
    margin-bottom: 1px;
  }
  .applications-icon-header{
    width: 23px;
    height: 23px;
    margin-bottom: 5px;
  }
  .dropdown-menu::after {
    left: 10%;
    right: 100%;
  }
}
.custom-profile {
  position: absolute;
  margin-left: 220px;
  padding-bottom: 10px;
  z-index: 10;
}
.d-md-none {
  .dropup,
  .dropright,
  .dropdown,
  .dropleft {
    position: initial;
  }
  .nav-dropdown .dropdown-menu {
    position: absolute;
  }
  .dropdown-menu:before {
    position: absolute;
    top: -6px;
    right: 4px;
    display: inline-block;
    border-right: 7px solid transparent;
    border-bottom: 7px solid #ccc;
    border-left: 7px solid transparent;
    border-bottom-color: rgba(0, 0, 0, 0.2);
    content: "";
  }

  .dropdown-menu:after {
    position: absolute;
    top: -5px;
    right: 5px;
    display: inline-block;
    border-right: 6px solid transparent;
    border-bottom: 6px solid #ffffff;
    border-left: 6px solid transparent;
    content: "";
  }
}
.lbl-app-nanme {
  font-size: 20px;
  color: #ffff;
  margin-bottom: 0;
  font-weight: bold;
  margin-left: 20px;
}
.app-name {
  color: $yellow;
  margin: 0;
}
.nav-icons {
  vertical-align: text-top;
  margin-right: 3px;
}
.nav-dropdown .dropdown-menu {
  height: auto !important;
}
.table-bordered thead th,
.table-bordered thead td {
  background-color: #f2f2f2 !important;
}
.navbar-dark.navbar-toggler {
  border-color: white;
}
.main-header {
  margin-left: 15px !important;
  display: flex;
  margin-top: 10px;
  margin-bottom: 25px;
  height: 30px;
  width: 100%;
}
.back-icon{
  display: flex;
}
.sub-container {
  margin-left: 15px;
}
.task-head {
  font-weight: bold;
  font-size: 30px;
  margin-left: 10px !important;
  //display: flex;
}
.icon-wp-forms{
  height: 30px;
}
.forms-text{
  vertical-align: bottom;
  margin-left: 10px;
  margin-bottom: 0px;
}
.task-head-icons{
  height: 30px;
  width: 30px;
}
.task-head-details {
  font-weight: bold;
  font-size: 30px;
  margin-left: 10px !important;
}
.icon-wp-forms{
  height: 30px;
}
.forms-text{
  vertical-align: middle;
  margin-left: 10px;
}
.btn-right {
  float: right;
  margin-left: auto;
}
.btn.btn-link:focus,
.btn.btn-link.focus {
  box-shadow: none;
}
.div-center {
  align-self: center;
}
.tooltip-inner {
  color: black !important;
  background-color: white !important;
  border: 0.5px solid !important;
  text-align: left;
}

.navbar-brand {
  align-items: center;
}

.navbar-brand .img-fluid,
.navbar-brand .img-thumbnail {
  max-width: 100%;
  height: 100%;
}

select option:hover {
  box-shadow: 0 0 10px 100px #000 inset;
}
.modal-dialog {
  margin: 8.75rem auto !important;
}

#main {
  background-color: #fff;
  padding: 10px;
  box-shadow: 0 3px 3px -2px rgba(0, 0, 0, 0.2),
    0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}

#sidebar {
  background: #fff;
}

.content {
  background-color: #f8f8f8;
}

#sidebar ul li.active > a,
#sidebar a[aria-expanded="true"] {
  background: #fff;
}

header nav {
  border-bottom: #fff;
}

.topheading-border-bottom {
  z-index: 2000;
  border-bottom: 2px solid #4d61fc !important;
  padding: 0 0.5rem !important;
  box-shadow: 0 3px 3px -2px rgba(0, 0, 0, 0.2),
    0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}

.rounded-circle {
  border-radius: 50% !important;
  border: 2px solid lightgrey;
  height: 40px;
  width: 40px;
}

.custom-app-name {
  font-size: 30px;
  font-weight: 600;
  /*margin-left: -15px!important;*/
  color: black;
}

div[disabled] {
  pointer-events: none;
  opacity: 0.7;
}

.hide-scroll {
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}
.css-79elbk {
 // position: none;
}
body ::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 15px !important;
}

.MuiFormLabel-root.Mui-error {
  color: #9E2929 !important;
}

.MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
  border-color: #9E2929 !important;
}

.MuiFormLabel-asterisk.Mui-error {
  color: #9E2929 !important;
}

.MuiFormHelperText-root.Mui-error {
  color: #9E2929 !important;
}

.btn-link {
  color: #036;
}

body ::-webkit-scrollbar-track {
  background: none !important;
}
.actionsBtn {
  background-color: transparent;
  border: none;
  padding: 6px 12px;
  border-radius: 50%;
  cursor: pointer;

  &:hover {
    outline:none;
    background-color: #dbdbdb5d;
  }
  &:focus {outline:0;}        
}

.modal-link-button {
  text-decoration: underline;
}

.btn-save {
    border-radius: 3px;
    font-size: small;
    height: 30px;
    
    flex-basis: 50% 
}

.btn-save:disabled {
    opacity: 0.65;
}

// .btn-cancel {
//     border: 1px solid #38598A;
//     background-color: #FFFFFF;
//     color: #38598A;
//     border-radius: 3px;
//     font-size: small;
//     height: 30px;
//     flex-basis: 50%;
// }

#extension-dialog-title {
    font-family: "BCSans-Bold", sans-serif!important; 
    padding: 30px 26px 30px 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.extension-header {
    margin-left: 30px;
    color: #036 ;
    font-size: xx-large;
    font-weight: bold;
    font-family: "BCSans-Bold", sans-serif !important;
    flex-direction: column;
    flex-basis: 70% 
}

.title-col3 {
    flex-basis: 5% !important
}

#extension-dialog-title .MuiIconButton-root {
    background-color: #036 ;
    color: white;
    border-radius: unset;
}

.dialog-content {
    margin: auto;
}

.dialog-content-nomargin {
    margin: initial;
}

.new-extension-link {
    font-weight: 400;
    color: #036;
}



.foi-request-review-header-row1 {
    display: flex;
}
.foi-request-review-header-col1{
    display: block;
    
}
.foi-request-status {
   //font-family: "BCSans-Regular", sans-serif;
    background-color: black;
    color: white;
    width: fit-content;
    margin-top: 10px;    
    padding: 3px 25px;
    text-transform: capitalize;
}

.foi-request-daysremaining {
    //font-family: "BCSans-Regular", sans-serif;   
    width: fit-content;
    background-color: #008000;
    color: #FFFFFF;
    margin-top: 10px;    
    padding: 3px 25px;
    text-transform: capitalize;
}
.foi-request-review-header-col1-row {
    display: inline-flex;
    height: fit-content;
}

.foi-review-request-text {
   font-family: "BCSans-Bold", sans-serif!important;
    color: #036 ;
}

.foi-period-text {
   font-family: "BCSans-Bold", sans-serif!important;
    color: #036 ;
    padding-left: 10px;
    padding-right: 10px;

}
.foi-correspondence-text {
   font-family: "BCSans-Bold", sans-serif!important;
    color: #49648d;
}

.foi-assigned-to-container {
    margin-left: auto;
    display: inline-block;
}

.foi-assigned-to-inner-container {
    display: inline-block;
    margin-top: 5px;
    min-width: 350px;
    //margin-right: 8px;
}
.foi-assigned-to-inner-container .makeStyles-item-35 {
    padding-right: 24px !important;
}

.foi-assignee-dropdown {
    //display: inline-block;
    margin-top: 5px;
    min-width: 350px;
    float: right;
}

.axis-request-id {
    margin-top: 20px !important;
}

@media (max-width: 1242px) {
    .foi-request-review-header-col1-row {
        display:block;
    }
}
@import "./themes.scss";
@import "./base.scss";

.content {
 // padding-bottom: 2.5rem;
}

.main-container{
  height:80vh;
  min-height: 90vh;
  padding-top: 30px;
  margin-top: 2.8rem;
  //position: fixed;
/*  margin-left:250px !important;*/
}

.react-bootstrap-table table {
  table-layout: auto !important;
  position:relative !important;
}
.react-bootstrap-table{
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
@media (max-width: 768px) {
  .main-container{
    margin-left:0 !important;
  }
}

#app {
  background: $white;
  font-family: 'BCSans-Regular', sans-serif !important;
}


body {
  font-size: 16px;
  font-family: 'BCSans-Regular', sans-serif !important;
}

div , a, p, input, span, h1,h2,h3,h4 {
  font-family: 'BCSans-Regular', sans-serif;
}

[class^="Mui"]
{
  font-family: 'BCSans-Regular', sans-serif!important;
}

[class^="Mui"] > *
{
  font-family: 'BCSans-Regular', sans-serif!important;
}
